import * as Sentry from '@sentry/browser';
import {getCookie} from 'typescript-cookie';

declare var APP_ENV: string;
declare var APP_RELEASE: string|undefined;

if (APP_ENV !== 'local') {
    const dsn = document.querySelector('meta[name="sentry-dsn"]')?.getAttribute('content');

    if (typeof dsn === 'undefined' || dsn === null) {
        throw new Error('Sentry DSN not found. Expected to find <meta name="sentry-dsn" content="...">')
    }

    Sentry.init({
        dsn: dsn,
        environment: APP_ENV,
        release: APP_RELEASE,
        integrations: [],
        tracesSampleRate: 1.0,
        tunnel: '/sentry-tunnel',
        transportOptions: {
            headers: {
                'X-XSRF-TOKEN': getCookie('XSRF-TOKEN') || '',
            },
        }
    });
}
