/**
 * Heavily inspired by https://github.com/paulirish/lite-youtube-embed
 */

import {PrivacyOverlay} from '@freshleafmedia/privacy-banner/src/Elements/privacy-overlay';

class Embed extends HTMLElement
{
    connectedCallback() {
        this.addEventListener('click', this.addIframe);
        const privacyOverlay = this.querySelector<PrivacyOverlay>('privacy-overlay');

        if (privacyOverlay !== null) {
            privacyOverlay.addEventListener('enable', () => this.addIframe());
        }
    }

    addIframe() {
        const iframeEl = document.createElement('iframe');
        iframeEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        iframeEl.allowFullscreen = true;
        iframeEl.src = this.getAttribute('url') ?? '';
        this.append(iframeEl);

        iframeEl.focus();
    }
}

customElements.define('video-embed', Embed);
