
import './sentry'
import '@freshleafmedia/privacy-banner/src/index'
import 'wicg-inert'
import './video/embed'
import './code/highlighting'

const imageResizeObserver = new ResizeObserver((entries) => {
    // TODO: Add debounce
    for (const entry of entries) {
        (entry.target as HTMLImageElement).sizes=entry.target.getBoundingClientRect().width+'px'
    }
});

document.querySelectorAll('img')
    .forEach((element) => {
        imageResizeObserver.observe(element);

        element.addEventListener('error', () => {
            imageResizeObserver.unobserve(element);
        });
    });
